import ErrorHandlerService from '@/modules/common/services/error-handler.service'
import Notification from '@/modules/common/services/notification.service'
import Container from 'typedi'
import { Component, Vue, Watch } from 'vue-property-decorator'
import { mapState } from 'vuex'

@Component({
  name: 'GtrEventReportsView',
  computed: {
    ...mapState('event', ['event', 'events', 'eventAllContent']),
    ...mapState('report', ['reports', 'report', 'reportSnapshotResultDownload']),
    ...mapState('formbuilder', ['form']),
    ...mapState('module', ['activatedEventModules'])
  }
})
export default class GtrEventReportsView extends Vue {
  // TODO(z): Create specific types for these two.
  activatedEventModules!: Record<string, any>
  event!: Record<string, any>
  events!: Record<string, any>
  form!: Record<string, any>

  data () {
    return {
      table: {
        loading: false,
        search: '',
        headers: [
          { text: 'Name', align: 'start', sortable: true, filterable: true, value: 'name' },
          { text: 'Category', align: 'center', sortable: true, filterable: true, value: 'report_category' },
          { text: '', align: 'center', sortable: false, filterable: false, value: 'actions', width: '90px' }
        ],
        items: []
      },
      eventToCopyTo: null,
      reportToCopy: null,
      makeAvailableToImport: false,
      reportToDelete: null,
      showExportDialog: false,
      showCopyDialog: false,
      duplicateReportName: '',
      copyErrors: null
    }
  }

  async mounted () {
    // I will need events for the copy dialog.
    if (this.events.length === 0) {
      await this.$store.dispatch('event/loadEvents')
    }
    await this.fetchForm()
    await this.fetchReports()
  }

  @Watch('reports', { immediate: true })
  onReportsChange (payload: any) {
    if (payload.data) {
      this.$data.table.items = payload.data
    }
  }

  @Watch('report', { immediate: true })
  onReportSnapshotResultChange (payload: any) {
    if (payload?.status === 'COMPLETE') {
      this.handleCloseExportDialog()
    }
  }

  @Watch('reportSnapshotResultDownload', { immediate: true })
  onReportSnapshotResultDownloadChange (payload: any) {
    if (payload.url) {
      location.href = payload.url
    }
  }

  set errors (errors: any) {
    this.$data.copyErrors = errors
  }

  get errors () {
    return `${this.$data.copyErrors.message}: ${this.$data.copyErrors.fields.map(field => `${field.label} (${field.type})`).join(', ')}`
  }

  get availableEvents () {
    const company = this.events.find((company) => company.uuid === this.$route.params.uuid)
    if (!company) return []
    return company.events.filter(event => event.uuid !== this.event.uuid)
  }

  get activeReports () {
    const a2zTenantId = this.$store.state.event.eventAllContent.settings.a2z_tenant_id
    const mcproTenantId = this.$store.state.event.eventAllContent.settings.mcpro_client_id
    const mctradeTenantId = this.$store.state.event.eventAllContent.settings.mctrade_client_id
    const reports: any[] = []
    this.$data.table.items.forEach(report => {
      if ((report.report_type === 'A2Z_ALLOTTED_BADGES' || report.name === 'A2Z Exhibitor Participants') && !a2zTenantId) {
        return
      } else if (report.name === 'MC Pro SSO Participants' && !mcproTenantId) {
        return
      } else if (report.name === 'MC Trade SSO Participants' && !mctradeTenantId) {
        return
      } else if (report.name.toLowerCase() === 'badges printed by hour' && (new Date() < new Date(this.event.event_start_date))) {
        return
      }
      if (report.module) {
        if (
          this.activatedEventModules[report.module].enabled &&
          report.name !== 'Not Attended Report'
        ) {
          switch (report.module) {
            case 'SESSION_TRACKING': {
              const regFormFields = this.form?.page_data.flatMap(page => page.fields)
              if (report.report_type === 'SESSION_ACCESS' && regFormFields?.find(field => field.type === 'sessions' && field.visible)) { // if sessions field is on reg form
                reports.push(report)
              }
              break
            }
            case 'BADGES':
              if (Vue.prototype.$hasPermission('badges.badge_printing_reports.view')) {
                reports.push(report)
              }
              break
            default:
              reports.push(report)
          }
        }

        // if the end date has passed and it's not already in the list.
        if (
          report.name === 'Not Attended Report' &&
          (new Date() > new Date(this.event.event_end_date))
        ) {
          reports.push(report)
        }
      } else {
        reports.push(report)
      }
    })

    return reports
  }

  async handleExportReport (report: any) {
    try {
      await this.$store.dispatch('common/showLoader', { value: true })
      this.$data.showExportDialog = true
      const payload: any = {
        event_uuid: this.$route.params.event_uuid,
        report_uuid: report.uuid,
        export_report: true
      }
      await this.$store.dispatch('report/getReportSnapshot', payload)
    } catch (error) {
      Container.get(ErrorHandlerService).error(error)
    } finally {
      await this.$store.dispatch('common/hideLoader')
    }
  }

  handleShowCopyDialog (report: any): void {
    this.$data.reportToCopy = report
    this.$data.showCopyDialog = true
    this.$data.duplicateReportName = `${report.name} - Copy`
  }

  // handleDuplcateReport takes the report to duplicate and sends a request to
  async handleDuplicateReport (): Promise<void> {
    try {
      await this.$store.dispatch('common/showLoader', { value: true })
      const notificationService: Notification = Container.get(Notification)
      const source_event_uuid = this.$route.params.event_uuid
      const target_event_uuid = this.$data.eventToCopyTo ? this.$data.eventToCopyTo : this.$route.params.event_uuid
      await this.$store.dispatch('report/copyReport', {
        source_event_uuid,
        target_event_uuid,
        report_uuid: this.$data.reportToCopy.uuid,
        report_name: this.$data.duplicateReportName
      })
      notificationService.success('Report successfully copied.')
      this.handleCloseCopyDialog()
      await this.fetchReports()
    } catch (error) {
      this.errors = error.data
      const errorService: ErrorHandlerService = Container.get(ErrorHandlerService)
      await errorService.error(error)
    } finally {
      await this.$store.dispatch('common/hideLoader')
    }
  }

  async onDeleteReportAction (payload: any) {
    if (payload.confirm) {
      try {
        this.$data.table.loading = true
        const payload: any = {
          event_uuid: this.$route.params.event_uuid,
          report_uuid: this.$data.reportToDelete.uuid
        }
        await this.$store.dispatch('report/deleteReport', payload)
        Container.get(Notification).success('Report successfully deleted.')
      } catch (error) {
        Container.get(ErrorHandlerService).error(error)
      } finally {
        this.$data.table.loading = false
      }
    }
    this.$data.reportToDelete = null
  }

  handleViewReport (report: any) {
    this.$router.push({
      name: 'level-two.event.reports.view',
      params: Object.assign(this.$route.params, {
        report_uuid: report.uuid
      })
    })
  }

  handleEditReport (report: any) {
    this.$router.push({
      name: 'level-two.event.reports.edit',
      params: Object.assign(this.$route.params, {
        report_uuid: report.uuid
      })
    })
  }

  handleDeleteReport (report: any) {
    this.$data.reportToDelete = report
  }

  private async fetchReports () {
    if (this.$route.params.event_uuid) {
      this.$data.table.loading = true
      await this.$store.dispatch(
        'report/getReports',
        this.$route.params.event_uuid
      )
      this.$data.table.loading = false
    }
  }

  private async fetchForm () {
    if (this.$route.params.event_uuid) {
      this.$data.table.loading = true
      await this.$store.dispatch('formbuilder/getForm', { event_uuid: this.$route.params.event_uuid })
      this.$data.table.loading = false
    }
  }

  handleCloseExportDialog () {
    this.$data.showExportDialog = false
  }

  /**
   * handleCloseCopyDialog closes the copy dialog and resets the data for the next time it's opened.
   */
  handleCloseCopyDialog (): void {
    this.$data.reportToCopy = null
    this.$data.duplicateReportName = ''
    this.$data.showCopyDialog = false
    this.errors = null
  }
}
